import axios from 'axios'
import settings from '../../settings'
const instance = axios.create({
  baseURL: `${settings.baseApiUrl}`
})

instance.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('user'))
  if (user && Object.hasOwn(user, 'key')) {
    config.headers.Authorization = `Token ${user.key}`
  }
  return config
}, (error) => { Promise.reject(error) })

export default instance
