<template lang="pug">
v-app(:key="key")
  v-main
    router-view
</template>
<script setup>
import { useAuthStore } from '@/stores/useAuthStore';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const authStore = useAuthStore()
const router = useRouter()
const key = ref(0)

authStore.$onAction(
  ({
    name, 
    store,
    args, 
    after,
    onError
  }) => {
    if (name === 'refreshMyPermissions'){
      after((result) => {
        key.value++
      })
    }
  }
)
try {
  authStore.refreshMyPermissions()
} catch (error) {
  console.error(error)
}

</script>
<style lang="scss">
@import "@/styles/sass/main.scss";
.list-items-container {
  border: 1px solid #e0e0e0; 
  padding: 30px;
}
</style>
