export const TRIGGER_TYPES = {
  CLOCKED_SCHEDULE : 'clocked_schedule',
  API: 'api',
}

export const TRIGGER_TYPES_DISPLAY = {
  [TRIGGER_TYPES.CLOCKED_SCHEDULE]: 'Fecha y hora',
  [TRIGGER_TYPES.API]: 'API',
}


export const ACTION_TYPES = {
  SEND_EMAIL: 'send_email',
  SEND_WHATSAPP: 'send_whatsapp',
}

export const ACTION_TYPES_DISPLAY = {
  [ACTION_TYPES.SEND_EMAIL]: 'Enviar correo',
  [ACTION_TYPES.SEND_WHATSAPP]: 'Enviar WhatsApp',
}


export const ACTION_TYPES_OPTIONS = [
  { value: ACTION_TYPES.SEND_EMAIL, title: ACTION_TYPES_DISPLAY[ACTION_TYPES.SEND_EMAIL] },
  { value: ACTION_TYPES.SEND_WHATSAPP, title: ACTION_TYPES_DISPLAY[ACTION_TYPES.SEND_WHATSAPP] },
]

export const TRIGGER_TYPES_OPTIONS = [
  { value: TRIGGER_TYPES.CLOCKED_SCHEDULE, title: TRIGGER_TYPES_DISPLAY[TRIGGER_TYPES.CLOCKED_SCHEDULE] },
  { value: TRIGGER_TYPES.API, title: TRIGGER_TYPES_DISPLAY[TRIGGER_TYPES.API] },
]
