import * as Sentry from '@sentry/vue'

import settings from '../../settings'

const registerSentry = (app) => {
  if (!settings.enableSentry) {
    console.log('Sentry is disabled: ' + settings.environment)
    return
  }

  console.log('Sentry is enabled: ' + settings.environment)
  Sentry.init({
    app,
    dsn: settings.dsn,
    environment: settings.environment,
    integrations: [],
  });
}

export default { registerSentry }
