export const DISABLEABLE_SECTIONS = {
  JOURNEY: 'enable_journey_section',
  EDUCATIVE_CONTENT: 'enable_educative_content_section',
  PATIENT_APP: 'enable_patient_app',
  SURVEY: 'enable_surveys_section',
  REPORTS: 'enable_reports_section',
  PANEL: 'enable_panel_section',
}

export const checkEnableSection = (section) => {
  const selectedEstablishment = JSON.parse(localStorage.getItem('selectedEstablishment'))
  if (selectedEstablishment) {
    return selectedEstablishment[section] === true
  }
  return false
}