export const USER_WRITE_PERMISSION = 'user.write'
export const USER_READ_PERMISSION = 'user.read'
export const USER_DELETE_PERMISSION = 'user.delete'

export const PATIENT_WRITE_PERMISSION = 'patient.write'
export const PATIENT_READ_PERMISSION = 'patient.read'
export const PATIENT_DELETE_PERMISSION = 'patient.delete'

export const PROJECT_WRITE_PERMISSION = 'project.write'
export const PROJECT_READ_PERMISSION = 'project.read'
export const PROJECT_DELETE_PERMISSION = 'project.delete'
export const PROJECT_USER_WRITE_PERMISSION = 'project.user.write'
export const PROJECT_USER_READ_PERMISSION = 'project.user.read'

export const ROLES_ESTABLISHMENT_READ_PERMISSION = 'roles.read'
export const ROLES_ESTABLISHMENT_WRITE_PERMISSION = 'roles.write'

export const ESTABLISHMENT_READ_PERMISSION = 'establishment.read'
export const ESTABLISHMENT_WRITE_PERMISSION = 'establishment.write'
export const ESTABLISHMENT_DELETE_PERMISSION = 'establishment.delete'

export const TAG_READ_PERMISSION = 'tag.read'
export const TAG_WRITE_PERMISSION = 'tag.write'
export const TAG_DELETE_PERMISSION = 'tag.delete'

export const GROUP_READ_PERMISSION = 'group.read'
export const GROUP_WRITE_PERMISSION = 'group.write'

export const JOURNEY_READ_PERMISSION = 'journey.read'
export const JOURNEY_WRITE_PERMISSION = 'journey.write'

export const EDUCATIVE_CONTENT_READ_PERMISSION = 'educative_content.read'
export const EDUCATIVE_CONTENT_WRITE_PERMISSION = 'educative_content.write'
export const EDUCATIVE_CONTENT_DELETE_PERMISSION = 'educative_content.delete'

export const PATIENT_APP_CONFIG_WRITE_PERMISSION = 'patient_app_config.write'

export const SURVEY_READ_PERMISSION = 'survey.read'
export const SURVEY_WRITE_PERMISSION = 'survey.write'
export const SURVEY_DELETE_PERMISSION = 'survey.delete'

export const REPORTS_READ_PERMISSION = 'reports.read'
export const REPORTS_WRITE_PERMISSION = 'reports.write'
export const REPORTS_DELETE_PERMISSION = 'reports.delete'

export const PANELS_READ_PERMISSION = 'panels.read'
export const PANELS_WRITE_PERMISSION = 'panels.write'
export const PANELS_DELETE_PERMISSION = 'panels.delete'


export const permissions = {
  USER_WRITE_PERMISSION,
  USER_READ_PERMISSION,
  USER_DELETE_PERMISSION,
  PATIENT_WRITE_PERMISSION,
  PATIENT_READ_PERMISSION,
  PATIENT_DELETE_PERMISSION,
  PROJECT_WRITE_PERMISSION,
  PROJECT_READ_PERMISSION,
  PROJECT_DELETE_PERMISSION,
  PROJECT_USER_WRITE_PERMISSION,
  PROJECT_USER_READ_PERMISSION,
  ROLES_ESTABLISHMENT_READ_PERMISSION,
  ROLES_ESTABLISHMENT_WRITE_PERMISSION,
  ESTABLISHMENT_READ_PERMISSION,
  ESTABLISHMENT_WRITE_PERMISSION,
  ESTABLISHMENT_DELETE_PERMISSION,
  TAG_READ_PERMISSION,
  TAG_WRITE_PERMISSION,
  TAG_DELETE_PERMISSION,
  GROUP_READ_PERMISSION,
  GROUP_WRITE_PERMISSION,
  JOURNEY_READ_PERMISSION,
  JOURNEY_WRITE_PERMISSION,
  EDUCATIVE_CONTENT_READ_PERMISSION,
  EDUCATIVE_CONTENT_WRITE_PERMISSION,
  EDUCATIVE_CONTENT_DELETE_PERMISSION,
  PATIENT_APP_CONFIG_WRITE_PERMISSION,
  SURVEY_READ_PERMISSION,
  SURVEY_WRITE_PERMISSION,
  SURVEY_DELETE_PERMISSION,
  REPORTS_READ_PERMISSION,
  REPORTS_WRITE_PERMISSION,
  REPORTS_DELETE_PERMISSION,
  PANELS_READ_PERMISSION,
  PANELS_WRITE_PERMISSION,
  PANELS_DELETE_PERMISSION,
}