import { permissions } from '@/utils/permissions'
import { createRouter, createWebHistory } from 'vue-router'
import { checkPermissions } from '@/utils/authUtils';

import JourneyView from '@/views/JourneyView.vue'
import { meta } from 'eslint-plugin-vue-pug/lib/rules/component-name-in-template-casing';
import { DISABLEABLE_SECTIONS, checkEnableSection } from '@/utils/app';

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Default/Default.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/HomeView.vue'),
        meta: {
          isPublic: false,
          parent: null
        }
      },
      {
        path: 'establishments',
        name: 'Establishments',
        redirect: { name: 'Establishments' },
        meta: {
          title: 'Establecimientos',
          isPublic: false,
          parent: null,
        },
        children: [
          {
            path: '',
            name: 'Establishments',
            component: () => import('@/views/EstablishmentsView.vue'),
          },
          {
            path: 'new-establishment',
            name: 'Nuevo Establecimiento',
            component: () => import('@/views/NewEstablishmentView.vue'),
            meta: {
              title: 'Nuevo Establecimiento',
              isPublic: false,
              permissions: [
                permissions.ESTABLISHMENT_WRITE_PERMISSION
              ],
              parent: {
                title: 'Establecimientos',
                path: '/establishments'
              }
            },
          },
          {
            path: 'establishment/:id',
            name: 'Editar Establecimiento',
            component: () => import('@/views/EditEstablishmentView.vue'),
            meta: {
              title: 'Editar Establecimiento',
              isPublic: false,
              permissions: [
                permissions.ESTABLISHMENT_WRITE_PERMISSION
              ]
            },
          },
        ]
      },
      {
        path: 'projects',
        name: 'Projects',
        component: () => import('@/views/ProjectsView.vue'),
        meta: {
          title: 'Proyectos',
          isPublic: false,
          permissions: [
            permissions.PROJECT_WRITE_PERMISSION,
            permissions.PROJECT_READ_PERMISSION
          ],
          parent: null
        }
      },
      {
        path: 'users',
        name: 'Usuarios',
        component: () => import('@/views/UsersView.vue'),
        meta: {
          title: 'Usuarios',
          isPublic: false,
          permissions: [
            permissions.USER_WRITE_PERMISSION,
            permissions.USER_READ_PERMISSION
          ],
          parent: null
        }
      },
      {
        path: 'projects',
        name: 'Proyectos',
        redirect: { name: 'Projects' },
        meta: {
          title: 'Proyectos',
          isPublic: false,
          parent: null
        },
        children: [
          {
            path: '',
            name: 'Projects',
            component: () => import('@/views/ProjectsView.vue'),
          },
          {
            path: 'project/:id',
            name: 'ProjectDetail',
            component: () => import('@/views/ProjectDetailView.vue'),
            meta: {
              title: 'Detalle del Proyecto',
              isPublic: false,
              parent: {
                title: 'Proyectos',
                path: '/projects'
              }
            }
          }
        ]
      },
      {
        path: 'project/:id/new-content',
        name: 'NewProjectContent',
        component: () => import('@/views/NewEducativeContentView.vue'),
        meta: {
          title: 'Nuevo Contenido Educativo',
          isPublic: false,
          parent: null,
          section: DISABLEABLE_SECTIONS.EDUCATIVE_CONTENT
        }
      },
      {
        path: 'project/:id/new-content/:contentId',
        name: 'crearContenidoEducativoUnlayer',
        component: () => import('@/views/NewEducativeContentUnlayerView.vue'),
        meta: {
          title: 'Nuevo Contenido Educativo',
          isPublic: false,
          parent: null,
          section: DISABLEABLE_SECTIONS.EDUCATIVE_CONTENT
        }
      },
      {
        path: 'project/:id/content/:contentId',
        name: 'editarContenidoEducativo',
        component: () => import('@/views/NewEducativeContentView.vue'),
        meta: {
          title: 'Editar Contenido Educativo',
          isPublic: false,
          parent: null,
          section: DISABLEABLE_SECTIONS.EDUCATIVE_CONTENT
        }
      },
      {
        path: 'project/:id/new-survey',
        name: 'NewSurvey',
        component: () => import('@/views/NewSurvey.vue'),
        meta: {
          title: 'Nueva Encuesta',
          isPublic: false,
          parent: null,
          section: DISABLEABLE_SECTIONS.SURVEY
        }
      },
      {
        path: 'project/:id/new-survey/:surveyID',
        name: 'UpdateSurvey',
        component: () => import('@/views/NewSurvey.vue'),
        meta: {
          title: 'Editar Encuesta',
          isPublic: false,
          parent: null,
          section: DISABLEABLE_SECTIONS.SURVEY
        }
      },
      {
        path: 'project/:id/survey/:surveyID',
        name: 'DetailSurvey',
        component: () => import('@/views/NewSurvey.vue'),
        meta: {
          title: 'Ver Encuesta',
          isPublic: false,
          parent: null,
          section: DISABLEABLE_SECTIONS.SURVEY
        }
      },
      {
        path: 'patients',
        name: 'Pacientes',
        redirect: { name: 'Patients' },
        meta: {
          title: 'Pacientes',
          isPublic: false,
          permissions: [
            permissions.PATIENT_WRITE_PERMISSION,
            permissions.PATIENT_READ_PERMISSION
          ],
          parent: null
        },
        children: [
          {
            path: '',
            name: 'Patients',
            component: () => import('@/views/PatientsView.vue'),
          },
          {
            path: 'new-patient',
            name: 'Nuevo Paciente',
            component: () => import('@/views/NewPatientView.vue'),
            meta: {
              title: 'Nuevo Paciente',
              isPublic: false,
              permissions: [
                permissions.PATIENT_WRITE_PERMISSION,
              ],
              parent: {
                title: 'Pacientes',
                path: '/patients'
              }
            },
          },
          {
            path: 'patient/:id',
            name: 'Editar Paciente',
            component: () => import('@/views/EditPatientView.vue'),
            meta: {
              title: 'Editar Paciente',
              isPublic: false,
              permissions: [
                permissions.PATIENT_WRITE_PERMISSION,
              ]
            },
          },
          {
            path: 'patient/:id/ver',
            name: 'Detalle Paciente',
            component: () => import('@/views/PatientDetailView.vue'),
            meta: {
              title: 'Detalle del Paciente',
              isPublic: false,
              parent: {
                title: 'Pacientes',
                path: '/patients'
              }
            },
          },
          {
            path: 'patient/group/:groupId',
            name: 'Pacientes por grupo',
            component: () => import('@/views/PatientsView.vue'),
            meta: {
              isPublic: false,
              parent: {
                title: 'Pacientes',
                path: '/patients'
              }
            },
          },

        ]
      },
      {
        path: 'roles',
        name: 'Roles',
        redirect: { name: 'RolesList' },
        meta: {
          title: 'Roles',
          isPublic: false,
          parent: null,
          permissions: [
            permissions.ROLES_ESTABLISHMENT_READ_PERMISSION,
            permissions.ROLES_ESTABLISHMENT_WRITE_PERMISSION
          ]
        },
        children: [
          {
            path: '',
            name: 'RolesList',
            component: () => import('@/views/RolesView.vue'),
            meta: {
              title: 'Roles',
              isPublic: false,
              permissions: [
                permissions.ROLES_ESTABLISHMENT_READ_PERMISSION,
                permissions.ROLES_ESTABLISHMENT_WRITE_PERMISSION
              ]
            },
          },
          {
            path: ':id',
            name: 'Editar Rol',
            component: () => import('@/views/EditRolePermissions.vue'),
            meta: {
              title: 'Editar Rol',
              isPublic: false,
              permissions: [
                permissions.ROLES_ESTABLISHMENT_WRITE_PERMISSION
              ],
              parent: {
                title: 'Roles',
                path: '/roles'
              }
            },
          }
        ]
      },
      {
        path: 'tags',
        name: 'Tags',
        redirect: { name: 'TagsList' },
        meta: {
          title: 'Etiquetas',
          isPublic: false,
          parent: null,
          permissions: [
            permissions.TAG_READ_PERMISSION,
          ],
        },
        children: [
          {
            path: '',
            name: 'TagsList',
            component: () => import('@/views/TagsView.vue'),
            meta: {
              title: 'Etiquetas',
              isPublic: false,
            },
          },
        ]
      },
      {
        path: 'groups',
        name: 'Grupos',
        redirect: { name: 'Groups' },
        meta: {
          title: 'Grupos',
          isPublic: false,
          parent: null
        },
        children: [
          {
            path: '',
            name: 'Groups',
            component: () => import('@/views/GroupsView.vue'),
          },
        ]
      },
      {
        path: 'journey/:id',
        name: 'Journey',
        component: () => import('@/views/JourneyView.vue'),
        meta: {
          title: 'Journey',
          isPublic: false,
          parent: null,
          permissions: [
            permissions.JOURNEY_READ_PERMISSION
          ],
          section: DISABLEABLE_SECTIONS.JOURNEY
        },
      },
      {
        path: 'panels',
        name: 'Panels',
        redirect: { name: 'PanelsList' },
        meta: {
          title: 'Paneles',
          isPublic: false,
          parent: null,
          section: DISABLEABLE_SECTIONS.PANEL
        },
        children: [
          {
            name: 'PanelsList',
            path: '',
            component: () => import('@/views/PanelsView.vue'),
            meta: {
              title: 'Paneles',
              isPublic: false,
              parent: null,
            },
          },
          {
            name: 'DetallePanel',
            path: ':id',
            component: () => import('@/views/PanelDetailView.vue'),
            meta: {
              title: 'Detalle Panel',
              isPublic: false,
              parent: {
                title: 'Paneles',
                path: '/panels'
              }
            },
          },
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      isPublic: true,
    }
  },
  {
    path: '/recover-password',
    name: 'RecoverPassowrd',
    component: () => import('@/views/RecoverPasswordView.vue'),
    meta: {
      isPublic: true,
    }
  },
  {
    path: '/recover-password-confirm/:uid/:token',
    name: 'RecoverPassowrdConfirm',
    component: () => import('@/views/RecoverPasswordConfirmView.vue'),
    meta: {
      isPublic: true,
    }
  },
]
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'))

  if (to.name === 'Login' && user && Object.hasOwn(user, 'key')) {
    return next({ name: 'Home' })
  }
  if (to.name === 'Login' || to.name === 'RecoverPassowrd' || to.name === 'RecoverPassowrdConfirm') {
    return next()
  }
  if (!user && !to.meta.public) {
    return next({ name: 'Login' })
  }

  if (to.meta.section && !checkEnableSection(to.meta.section)) {
    return next({ name: 'Home' })
  }

  if (to.meta.permissions && !checkPermissions(to.meta.permissions)) {
    return next({ name: 'Home' })
  }
  next()
})

export default router
