import { defineStore } from 'pinia'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification'
import { getCurrentEstablishmentId } from '@/utils/authUtils.js'

const toast = useToast()

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: {},
    recoverPassword: {
      new_password1: '',
      new_password2: '',
      uid: '',
      token: ''
    },
    roles: []
  }),

  getters: {
  },

  actions: {
    setUser(user) {
      this.user = user
      if (!user) {
        localStorage.removeItem('user')
        return
      }
      localStorage.setItem('user', JSON.stringify(user))
    },
    setRecoverData(data) {
      this.recoverPassword = data
    },

    async login(data) {
      return axios
        .post('/auth/login/', data)
        .then((res) => {
          this.setUser(res.data)
          return res
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 401) {
            this.setUser({
              rut: data.rut,
              password: data.password,
              email: err.response.data.email
            })
          }
          toast.error('Error al iniciar sesión')
          return err.response
        })
    },

    async recoverPassword(data) {
      return axios
        .post('/auth/password/reset/', data)
        .then((res) => {
          this.setRecoverData(res.data)
          toast.success('Correo enviado exitosamente')
          return res
        })
        .catch((err) => {
          toast.error('Error al enviar el correo. Intente nuevamente')
          return err.response
        })
    },

    async recoverPasswordConfirm(data) {
      return axios
        .post('/auth/password/reset/confirm/', data)
        .then((res) => {
          this.setRecoverData(res.data)
          toast.success('Contraseña actualizada exitosamente')
          return res
        })
        .catch((err) => {
          toast.error('Error al actualizar la contraseña. Intente nuevamente')
          return err.response
        })
    },

    async logout() {
      this.setUser(null)
      localStorage.clear()
    },

    async getRoles() {
      return axios
        .get('/authentication/roles/establishment-roles/')
        .then((res) => {
          this.roles = res.data
          return res
        })
        .catch((err) => {
          return err.response
        })
    },

    async getMyPermissions() {
      const establishmentId = getCurrentEstablishmentId()
      return axios
        .get(`/establishment/establishment/${establishmentId}/my-permissions/`)
        .catch((err) => {
          return err.response
        })
    },

    async refreshMyPermissions() {
      const response = await this.getMyPermissions()
      if (response.status === 200) {
        localStorage.setItem('myPermissions', JSON.stringify(response.data))
      }
    }
  }
})

