import { defineStore } from 'pinia'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification'
import { getCurrentEstablishmentId } from '@/utils/authUtils.js'

const toast = useToast()

export const useJourneyStore = defineStore('journey', {
  state: () => ({}),

  getters: {},

  actions: {
    getJourneyEventsForCalendar(params={}) {
      params.establishment = getCurrentEstablishmentId()
      return axios
        .get(`/journey/journey-events/for-calendar/`, { params })
        .then(response => ({ data: response.data, response }))
        .catch((error) => ({ error, response: error.response }))
    },
    getJourneyEvents(params={}) {
      params.establishment = getCurrentEstablishmentId()
      return axios
        .get(`/journey/journey-events/`, { params })
        .then(response => ({ data: response.data, response }))
        .catch((error) => ({ error, response: error.response }))
    },
    getJourneyEvent(id) {
      return axios
        .get(`/journey/journey-events/${id}/`)
        .then(response => ({ data: response.data, response }))
        .catch((error) => ({ error, response: error.response }))
    },
    createJourneyEvent(data) {
      return axios
        .post(`/journey/journey-events/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => ({ data: response.data, response }))
        .catch((error) => ({ error, response: error.response }))
    },
    updateJourneyEvent(id, data) {
      return axios
        .put(`/journey/journey-events/${id}/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => ({ data: response.data, response }))
        .catch((error) => ({ error, response: error.response }))
    },
    deleteJourneyEvent(id) {
      return axios
        .delete(`/journey/journey-events/${id}/`)
        .then(response => ({ data: response.data, response }))
        .catch((error) => ({ error, response: error.response }))
    },
    updateJourney(id, data) {
      return axios
        .put(`/journey/journey/${id}/`, data)
        .then(response => ({ data: response.data, response }))
        .catch((error) => ({ error, response: error.response }))
    }
  }
})