// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { es } from 'vuetify/locale'

// Composables
import { createVuetify } from 'vuetify'

const defaultTheme = {
  dark: false,
  colors: {
    primary: '#075EE7',
    accent: '#F7DD90',
    secondary: '#114597',
    tertiary: '#10AAE7',
    error: '#F04D62',
    success: '#34D39D',
    warning: '#FFC22F',
  }
}


// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  locale: {
    locale: 'es',
    messages: { es },
  },
  display: {
    // Breakpoint
    breakpoints: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1264,
      xl: 1904,
    },
    // Icons
    icons: {
      iconfont: 'mdi',
    },
    mobileBreakpoint: 'md',
  },
  theme: {
    defaultTheme: 'defaultTheme',
    themes: {
      defaultTheme
    },
  },
})