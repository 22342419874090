// Plugins
import { registerPlugins } from '@/plugins'
import "vue-toastification/dist/index.css";
import "qalendar/dist/style.css";
import mitt from 'mitt';

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

const app = createApp(App)

const emitter = mitt()
app.config.globalProperties.emitter = emitter


registerPlugins(app)

app.mount('#app')
