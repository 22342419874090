// Plugins
import vuetify from './vuetify'
import pinia from '@/stores'
import router from '@/router'
import Toast from 'vue-toastification'
import sentryPlugin from './sentry'

import '@/styles/sass/vendors/_toastification.scss'

const options = {
  transition: 'Vue-Toastification__bounce',
  position: 'bottom-right',
  maxToasts: 20,
  newestOnTop: true,
  timeout: 2000,
}

export function registerPlugins(app) {
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(Toast, options)

    sentryPlugin.registerSentry(app)
}
